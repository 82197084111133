var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('n-search-container',{attrs:{"active":true,"show-header":false,"show-search":""},on:{"search":_vm.search,"reset":_vm.reset},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}},[_c('n-search-input',{ref:"search",attrs:{"fields":_vm.searchFields},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-0-5"},[_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("general.entries")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),(_vm.$can('create', 'reward'))?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'create-reward' }}},[_vm._v(_vm._s(_vm.$t("button.create")))])],1):_vm._e()],1)],1),_c('div',[_c('n-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.loading,"current-page":_vm.params.page,"per-page":_vm.$store.state.pagination.perPage,"total":_vm.total},on:{"sort-changed":_vm.sortChanged},scopedSlots:_vm._u([{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(item.imageUrl)?_c('img',{attrs:{"src":item.imageUrl,"alt":item.rewardName,"height":"50px"}}):_vm._e()]}},{key:"cell(point)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.point))+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("field." + (item.type))))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[(_vm.$can('update', 'reward'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
                name: 'update-reward',
                params: { id: data.item.id },
              },"title":_vm.$t('general.update'),"variant":"primary","pill":"","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1):_vm._e()],1)]}}])}),_c('n-pagination',{staticClass:"mt-2",attrs:{"total":_vm.total,"per-page":_vm.$store.state.pagination.perPage,"page":_vm.params.page},on:{"change":_vm.list}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }