<template>
  <div>
    <load-profile />
    <!-- Search Container Card -->
    <n-search-container
      v-model="params"
      :active="true"
      :show-header="false"
      show-search
      @search="search"
      @reset="reset"
    >
      <n-search-input
        ref="search"
        v-model="params"
        :fields="searchFields"
      />
    </n-search-container>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col
            v-if="$can('create', 'reward')"
            class="text-right"
          >
            <b-button
              variant="primary"
              :to="{ name: 'create-reward' }"
            >{{ $t("button.create") }}</b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          @sort-changed="sortChanged"
        >
          <template #cell(image)="{ item }">
            <img
              v-if="item.imageUrl"
              :src="item.imageUrl"
              :alt="item.rewardName"
              height="50px"
            >
          </template>
          <template #cell(point)="{ item }">
            {{ item.point | currency }}
          </template>
          <template #cell(type)="{ item }">
            {{ $t(`field.${item.type}`) }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', 'reward')"
                v-b-tooltip.hover
                :to="{
                  name: 'update-reward',
                  params: { id: data.item.id },
                }"
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination.vue'
import NSearchContainer from '@/components/NSearchContainer.vue'
import NSearchInput from '@/components/NSearchInput.vue'
import NTable from '@/components/NTable.vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import TableFields from './tableFields'
import SearchInputs from './searchInput'

const RewardRepository = Repository.get('reward')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || '',
      },
      avatarText,
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
  },
  watch: {
    perPage() {
      this.list(1)
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.page = 1
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.$refs.search.reset()
      this.params.page = 1
      this.updateQuerySting()
      this.getData()
    },
    getData() {
      this.loading = true
      const params = {
        ...this.params,
        searchFields: this.searchFields,
      }
      RewardRepository.index(params).then(response => {
        const { data } = response.data
        this.items = [...data.list]
        this.total = data.total
        this.loading = false
      })
        .catch(() => {
          this.loading = false
        })
    },
  },
  setup() {
    const fields = TableFields
    const searchFields = SearchInputs
    return {
      fields,
      searchFields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
