export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'image',
    label: 'field.image',
  },
  {
    key: 'rewardName',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'code',
    label: 'field.code',
  },
  {
    key: 'type',
    label: 'field.type',
  },
  {
    key: 'point',
    label: 'field.point',
  },
  {
    key: 'status',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
